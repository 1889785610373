<template>
  <div>
    <iframe :src="url" id="mobsf" scrolling="auto" frameborder="0" width="100%" height="100%" style="position:absolute;top:0px;left: 0px;top:0px;left: 0px;"></iframe>
  </div>
</template>
 
 
<script>
export default {
  data() {
    return {
      url: "",
      url_arr: [
        "https://mk.successkaoyan.com/agreement/regist?client=0", // 注册协议
        "https://mk.successkaoyan.com/agreement/privacy?client=0", // 隐私政策
      ],
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    if (this.$route.params.id.indexOf("/") != -1) {
      this.url = this.$route.params.id;
    } else {
      this.url = this.url_arr[this.$route.params.id];
    }
  },
  created() {
    this.$emit("public_header", false);
  },
  beforeDestroy() {
    this.$emit("public_header", true);
  },
};
</script>
